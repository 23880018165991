import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { Menu, Popover, Tooltip, Button } from 'antd';
import { Link } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { getCookie } from '@/common/utils';

import { useStores } from '../../hook/index';
import './UserMenuComponent.less';
import { RouteType } from '../../route/index';
import { addUserInfoToStorage } from '../api/account';

interface MenuInfo {
  title: string;
  requireQuestionFlag?: boolean;
  item: Array<{
    content: string;
    to: string;
  }>;
}
// if (!localStorage.getItem('questionFlag')) {
//   let userInfo = getCookie('userInfoOPEN');
//   userInfo =
//     typeof userInfo === 'string' ? JSON.parse(userInfo) : userInfo;
//   if (userInfo) {
//     addUserInfoToStorage(userInfo);
//   }
// }
// // 是否展示题库查询栏
// alert(`stored questionFlag: ${localStorage.getItem('questionFlag')}`);
// const questionFlag = localStorage.getItem('questionFlag') === 'true';
// console.log(`stored questionFlag: ${localStorage.getItem('questionFlag')}`)
// console.log(1);

// 普通人员菜单
const COMMON = [
  {
    title: '我的任务',
    item: [
      {
        content: '标注任务',
        to: '/annotation/mark-list'
      }
    ]
  },
  {
    title: '题库管理',
    requireQuestionFlag: true,
    item: [
      {
        content: '题库查询',
        to: '/question/questionBank'
      }
    ]
  },
  {
    title: '个人中心',
    item: [
      {
        content: '个人信息',
        to: '/personnel/info'
      }
    ]
  }
];
const COMMONInspect = [
  {
    title: '我的任务',
    item: [
      {
        content: '质检任务',
        to: '/annotation/inspect-list'
      }
    ]
  },
  {
    title: '题库管理',
    requireQuestionFlag: true,
    item: [
      {
        content: '题库查询',
        to: '/question/questionBank'
      }
    ]
  },
  {
    title: '个人中心',
    item: [
      {
        content: '个人信息',
        to: '/personnel/info'
      }
    ]
  }
];
// 验收
const MANAGER = [
  {
    title: '业务管理',
    item: [
      {
        content: '项目管理',
        to: '/project/list'
      },
      {
        content: '工作流管理',
        to: '/project/workFlow'
      },
      {
        content: '数据统计',
        to: '/statistic/project'
      }
    ]
  },
  {
    title: '题库管理',
    item: [
      {
        content: '题库',
        to: '/question/list'
      }
    ]
  },
  {
    title: '下载中心',
    item: [
      {
        content: '质检记录',
        to: '/download/inspect-log'
      },
      {
        content: '导出数据',
        to: '/download/derived-data'
      }
    ]
  }
];
// 普通用户
const NORMAL = [
  {
    title: '业务管理',
    item: [
      {
        content: '项目管理',
        to: '/project/list'
      },
      {
        content: '工作流管理',
        to: '/project/workFlow'
      }
    ]
  },
  {
    title: '模版管理',
    item: [
      {
        content: '模版库',
        to: '/manage-template/template-library'
      }
    ]
  },
  {
    title: '数据管理',
    item: [
      {
        content: '数据检索',
        to: '/dataRetrieval/dataRetrieval'
      }
    ]
  }
];

const HUMAN_RESOURCE_DASHBORD = {
  content: '人效看板',
  to: '/admin/human-resource-dashboard'
};

const HUMAN_RESOURCE_DATA_DOWNLAOD = {
  content: '人效数据',
  to: '/download/human-resource'
};

// 人员管理面板
const HUMAN_RESOURCE_MARK_OPERATION = { // 人效管理在运营人员和管理员均可见，调整结构
  title: '人员管理',
  item: [HUMAN_RESOURCE_DASHBORD]
};

/*
* 管理员时，将人员管理中添加 成员管理 和 团队管理
* 以兼容 2.8 版本中要求运营人员也可以查看 人员管理 中的 人效看板 的需求
*/
const HUMAN_RESOURCE_OPERATOR = {
  title: '人员管理',
  item: [
    {
      content: '成员管理',
      to: '/admin/admin-member-manage'
    },
    {
      content: '团队管理',
      to: '/admin/admin-team-management'
    },
    HUMAN_RESOURCE_DASHBORD
  ]
};

// 运营
const MARK_OPERATION = [
  {
    title: '业务管理',
    item: [
      {
        content: '项目管理',
        to: '/project/list'
      },
      {
        content: '工作流管理',
        to: '/project/workFlow'
      },
      {
        content: '数据统计',
        to: '/statistic/project'
      }
    ]
  },
  {
    title: '模版管理',
    item: [
      {
        content: '模版库',
        to: '/manage-template/template-library'
      }
    ]
  },
  {
    title: '数据管理',
    item: [
      {
        content: '数据集',
        to: '/dataSet/list'
      },
      {
        content: '数据检索',
        to: '/dataRetrieval/dataRetrieval'
      }
    ]
  },
  {
    title: '题库管理',
    item: [
      {
        content: '题库',
        to: '/question/list'
      }
    ]
  },
  HUMAN_RESOURCE_MARK_OPERATION,
  {
    title: '下载中心',
    item: [
      {
        content: '质检记录',
        to: '/download/inspect-log'
      },
      HUMAN_RESOURCE_DATA_DOWNLAOD
    ]
  }
];

// 管理员
const OPERATION = [
  ...MARK_OPERATION
    .filter((section) =>
      section?.title !== '下载中心'
      && section?.title !== '人员管理'), // 剔除人员管理，从而保持人员管理位于列表尾部
  ...[
    {
      title: '下载中心',
      item: [
        {
          content: '质检记录',
          to: '/download/inspect-log'
        },
        {
          content: '价格信息',
          to: '/download/price-derived'
        },
        HUMAN_RESOURCE_DATA_DOWNLAOD
      ]
    },
    {
      title: '价格管理',
      item: [
        {
          content: '模版价格',
          to: '/price/template'
        }
      ]
    },
    HUMAN_RESOURCE_OPERATOR
  ]
];

// 标注团队负责人
const MARK_MANAGER = [
  {
    title: '业务管理',
    item: [
      {
        content: '项目管理',
        to: '/project/list'
      },
      {
        content: '数据统计',
        to: '/statistic/project'
      }
    ]
  },
  {
    title: '人员管理',
    item: [
      {
        content: '成员管理',
        to: '/personnel/member-manage'
      },

      {
        content: '标签管理',
        to: '/personnel/tags-manage'
      },
      {
        content: '小组管理',
        to: '/personnel/team-manage'
      }
    ]
  },
  {
    title: '个人中心',
    item: [
      {
        content: '个人信息',
        to: '/personnel/info'
      }
    ]
  },
  {
    title: '下载中心',
    item: [
      {
        content: '质检记录',
        to: '/download/inspect-log'
      }
    ]
  }
];

// 自定义 Hook 用于防抖读取 localStorage
function useDebouncedLocalStorage(
  key: string,
  debounceTime = 500,
  onNullResult?: (setter: Dispatch<SetStateAction<string | null>>) => void
): string | null {
  const [value, setValue] = useState(localStorage.getItem(key));
  const lastFetchTimeRef = useRef<number>(0);

  const now = Date.now();
  if (now - lastFetchTimeRef.current > debounceTime) {
    const newValue = localStorage.getItem(key);
    if (newValue === null) {
      if (onNullResult) {
        onNullResult(setValue);
      }
    }
    if (newValue !== value) setValue(newValue);
    lastFetchTimeRef.current = now;
  }

  return value;
}

const { SubMenu } = Menu;

const UserMenu: React.FC<{ route?: RouteType }> = ({ route }) => {
  const { user } = useStores();

  const [openKeys, setOpenKeys] = useState<Array<string>>([]);

  const [selectedKeys, setSelectedKeys] = useState<Array<string>>([]);

  const [menu, setMenu] = useState<Array<MenuInfo>>([]);

  if (!localStorage.getItem('questionFlag')) {
    let userInfo = getCookie('userInfoOPEN');
    userInfo =
      typeof userInfo === 'string' ? JSON.parse(userInfo) : userInfo;
    if (userInfo) {
      addUserInfoToStorage(userInfo);
    }
  }
  const questionFlag: boolean = useDebouncedLocalStorage(
    'questionFlag',
    500,
    (setter) => {
      if (!localStorage.getItem('questionFlag')) {
        let userInfo = getCookie('userInfoOPEN');
        userInfo =
          typeof userInfo === 'string' ? JSON.parse(userInfo) : userInfo;
        if (userInfo) {
          addUserInfoToStorage(userInfo);
          setter(userInfo.questionFlag);
        }
      }
    }
  ) === 'true';

  const handleOpenChange = (
    newOpenKeys:
      | React.Key[]
      | {
        key: React.Key;
        item: React.ReactInstance;
        trigger: string;
        open: boolean;
      }
  ): void => {
    const latestOpenKey = (newOpenKeys as [string]).find(
      (key: string) => !openKeys.includes(key)
    );
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  useEffect(() => {
    let menuData = [];
    switch (user.roleCode) {
      case 'COMMON_MARK':
        menuData = COMMON;
        break;
      case 'COMMON_INSPECT':
        menuData = COMMONInspect;
        break;
      case 'GROUP_OWNER':
      case 'GROUP_ADMIN':
        menuData = MARK_MANAGER;
        break;
      case 'OPERATION':
        menuData = OPERATION;
        break;
      case 'OPERATION_COMMON':
        menuData = MARK_OPERATION;
        break;
      case 'OPERATION_COMMON_NORMAL':
        menuData = NORMAL;
        break;
      default:
        menuData = MANAGER;
    }
    setMenu(menuData);
    setOpenKeys([menuData[0].title]);
  }, [user.roleCode]);

  useEffect(() => {
    if (route && route.crumbs) {
      setOpenKeys([route.crumbs[0]]);
      setSelectedKeys([`${route.crumbs[0]}&${route.crumbs[1]}`]);
    } else {
      setOpenKeys([]);
      setSelectedKeys([]);
    }
  }, [route]);

  // 构建菜单项数组
  // console.log(`questionFlag`, questionFlag);
  // console.log('menu', menu);
  const menuItems = menu.map((menuData) => {
    // console.log(`    ${menuData.title}: flag=${menuData.requireQuestionFlag}: `, !menuData.requireQuestionFlag || questionFlag);
    if (!menuData.requireQuestionFlag || questionFlag) {
      // console.log(`        ${menuData.title}: enter!`)
      const subMenuItems = menuData.item.map((itemData) => ({
        key: `${menuData.title}&${itemData.content}`,
        label: (
          <Link to={itemData.to} key={`${menuData.title}&${itemData.content}`}>
            <div style={{ width: '100%', height: '100%' }}>
              {itemData.content}
              {itemData.content === '人力数据分析' && (
                <span>
                  <Popover
                    placement="rightTop"
                    content={() => (
                      <p style={{ fontWeight: 'bold' }}>
                        辅助标注管理员分析团队成员的标注、
                        <br />
                        质检、验收等作业流程效率。
                        <br />
                        管理者可以依据作业效率进行绩效
                        <br /> 评定、人员奖惩等各类管理措施。
                      </p>
                    )}
                  >
                    <QuestionCircleOutlined style={{ marginLeft: '8px' }} />
                  </Popover>
                </span>
              )}
            </div>
          </Link>
        ),
      }));

      return {
        key: menuData.title,
        label: menuData.title,
        children: subMenuItems,
      };
    }
    // console.log(`        ${menuData.title}: return null!`);
    return null;
  }).filter(Boolean); // 过滤掉 null 值

  // console.log('  menuItems', menuItems); // 打印菜单数组，查看是否符合预期

  return (
    <div className="user-menu-container">
      <Menu
        theme="dark"
        mode="inline"
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        onOpenChange={handleOpenChange}
        style={{ width: 200 }}
        items={menuItems}
      />
    </div>
  );
};
export default UserMenu;
