/* eslint-disable @typescript-eslint/no-extra-parens */
import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Avatar, Button, Dropdown, Menu, message } from 'antd';
import type { MenuProps } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../hook/index';
import { logout, deleteUserInfoToStorage } from '../api/account';
import './UserInfoComponent.less';
import ChangePsd from './ChangePsd';

const UserInfo: React.FC = observer(() => {
  const { user } = useStores();
  const history = useHistory();
  const platforms = ['团队管理', '团队成员-标注', '团队成员-质检'];
  const [userNickName, setUserNickName] = useState('');
  const [showChangePsd, setShowChangePsd] = useState(false);
  const [showChangePhone, setShowChangePhone] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState('标注平台');
  const handleLogout = useCallback(() => {
    logout().finally(() => {
      user.deleteUser();
      deleteUserInfoToStorage();
      history.push('/login');
    });
  }, [history, user]);
  const handleChangePsd = useCallback(() => {
    setShowChangePsd(true);
  }, [history, user]);
  const changePsdSuccess = useCallback((flag) => {
    if (flag) {
      if (flag) {
        message.success('修改成功，请重新登录');
        setTimeout(() => {
          window.location.href = '/';
        }, 500);
      }
    }
  }, []);
  const handleChangePhone = useCallback(() => {
    setShowChangePhone(true);
  }, []);
  const handleSelectPlatform = (
    platform: React.SetStateAction<string>
  ): void => {
    setSelectedPlatform(platform);
  };
  const userOperationList = [
    ...(Number(localStorage.getItem('registerSource')) === 0 &&
      String(localStorage.getItem('phone')) !== 'undefined'
      ? [
        { content: '更改手机号', operation: handleChangePhone },
        { content: '修改密码', operation: handleChangePsd }
      ]
      : []),
    { content: '退出', operation: handleLogout }
  ];
  const items: MenuProps['items'] = userOperationList.map((item) => ({
    key: item.content,
    label: (
      <div style={{ textAlign: 'center', width: '100%' }}>{item.content}</div>
    ),
    onClick: item.operation,
  }
  ));

  useEffect(() => {
    setUserNickName(user.nickname);
  }, [user, user.nickname]);

  return (
    <div className="user-info-container">
      <Dropdown
        menu={{ items }}
        placement="bottom"
        overlayClassName="operate"
      >
        <div>
          <Avatar
            size="small"
            style={{
              backgroundColor: '#ffffff',
              color: '#368BF2',
              border: '1px solid #368BF2'
            }}
            icon={<UserOutlined />}
          />
          <span className="user-name">{userNickName}</span>
        </div>
      </Dropdown>
      {showChangePsd && (
        <div
          className="change-psd-box-shadow"
          onClick={(e) => {
            setShowChangePsd(false);
            e.preventDefault();
          }}
        >
          <div
            className="change-psd-box"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ChangePsd
              type="password"
              clearAll={false}
              title="修改密码"
              initCallback={(e: any) => changePsdSuccess(e)}
            />
          </div>
        </div>
      )}
      {showChangePhone && (
        <div
          className="change-psd-box-shadow"
          onClick={(e) => {
            setShowChangePhone(false);
            e.preventDefault();
          }}
        >
          <div
            className="change-psd-box"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ChangePsd
              type="phone"
              clearAll={false}
              title="更改手机号"
              initCallback={(e: any) => changePsdSuccess(e)}
            />
          </div>
        </div>
      )}
    </div>
  );
});
export default UserInfo;
