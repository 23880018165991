import axios, { AxiosPromise } from 'axios';
import { baseURL } from '@/common/config';
import request from '../../common/request';

const baseUrl = '/platform/mark';

export interface endMarkParamsType {
  dataId: number;
  taskId: string;
}

export function endMark(params: endMarkParamsType): AxiosPromise {
  return request(`${baseUrl}/endMark`, {
    method: 'POST',
    data: params
  });
}

export interface TaskListParamsType {
  pageNum: number;
  pageSize: number;
  taskName?: string;
  status?: string;
}

export function getTaskList(params: TaskListParamsType): AxiosPromise {
  return request(`${baseUrl}/get/tasks`, {
    method: 'POST',
    data: params
  });
}

export interface TemplateInfoParamsType {
  taskId: string;
}

export function getTemplateInfo(params: TemplateInfoParamsType): AxiosPromise {
  return request(`${baseUrl}/get/taskInfo?taskId=${params.taskId}`, {
    method: 'GET'
  });
}

export interface TaskInfoParamsType {
  taskId: string;
}

export function getTaskInfo(params: TaskInfoParamsType): AxiosPromise {
  return request(`${baseUrl}/get/dataInfo?taskId=${params.taskId}`, {
    method: 'GET'
  });
}

export interface TemporarySaveParamsType {
  dataId: number;
  taskId: string;
  tempData: string;
}

export function temporarySave(params: TemporarySaveParamsType): AxiosPromise {
  return request(`${baseUrl}/submit/temp/data`, {
    method: 'POST',
    data: params
  });
}

export interface SkipParamsType {
  dataId: number;
  taskId: string;
}

export function skip(params: SkipParamsType): AxiosPromise {
  return request(
    `${baseUrl}/skip/data?dataId=${params.dataId}&taskId=${params.taskId}`,
    {
      method: 'GET'
    }
  );
}

export interface SubmitParamsType {
  dataId: number;
  valid: number;
  markData: string;
  statisticsData: string;
  taskId: string;
}

export function submit(params: SubmitParamsType): AxiosPromise {
  return request(`${baseUrl}/submit/data`, {
    method: 'POST',
    data: params
  });
}

export interface DataInfoParamsType {
  dataId: number;
  taskId: string;
}

export function getDataInfo(params: DataInfoParamsType): AxiosPromise {
  return request(
    `${baseUrl}/get/statistic/data?dataId=${params.dataId}&taskId=${params.taskId}`,
    {
      method: 'GET'
    }
  );
}

export interface SaveModifyDataParamsType {
  dataId: number;
  markData: string;
  remark: string;
  statisticsData: string;
  taskId: string;
  valid: number;
}

export function saveModifyData(params: SaveModifyDataParamsType): AxiosPromise {
  return request(`${baseUrl}/submit/modify/data`, {
    method: 'POST',
    data: params
  });
}

export interface GetReviseDataParamsType {
  taskId: string;
}

// 获取去修改的数据
export function getReviseData(params: GetReviseDataParamsType): AxiosPromise {
  return request(`${baseUrl}/get/revise/data?taskId=${params.taskId}`, {
    method: 'GET'
  });
}

export function getSubmitedDataId(params: any): AxiosPromise {
  return request(`${baseUrl}/marked/dataId?taskId=${params.taskId}`, {
    method: 'GET'
  });
}
// 教育通用OCR
export function getOcrData(params: any): AxiosPromise {
  return request(`${baseUrl}/ocr`, {
    method: 'POST',
    data: params
  });
}
// 获取gpt内容
export function getGptData(params: any): AxiosPromise {
  return request(`${baseUrl}/online`, {
    method: 'POST',
    data: params,
    timeout: 60000
  });
}

export function gptLoginData(params: any): AxiosPromise {
  return request(`${baseUrl}/learning/login`, {
    method: 'POST',
    data: params,
    timeout: 60000
  });
}

export function createGptSessionTalk(params: any, token: string): AxiosPromise {
  return request(`${baseUrl}/learning/dialogue/create`, {
    method: 'POST',
    data: params,
    timeout: 60000,
    headers: {
      'x-user-token': token
    }
  });
}

// 试题录入
export function imagrUpload(params: any): AxiosPromise {
  return request(`${baseUrl}/oss/image/upload`, {
    method: 'POST',
    data: params,
    timeout: 60000
  });
}
// 试卷拆录
export function paperSplit(params: any): AxiosPromise {
  return request(`${baseUrl}/paper-split`, {
    method: 'POST',
    data: params
  });
}
// stf 试题分析-相似题
export function queryJiaoYanYun(params: any): AxiosPromise {
  return request('/platform/question/queryJiaoYanYun', {
    method: 'POST',
    data: params
  });
}
// stf 试题分析-试题解析
export function queryTPP(params: any): AxiosPromise {
  return request('/platform/question/queryTPP', {
    method: 'POST',
    data: params
  });
}
// sft 预览功能
export function getDataPreViewList(params: any): AxiosPromise {
  return request('/platform/dataSet/get/dataPreViewList', {
    method: 'POST',
    data: params
  });
}
// 查询用户快捷键
export function queryShortcutKyes(params: any): AxiosPromise {
  return request('/platform/shortcut/queryShortcutKeys', {
    method: 'POST',
    data: params
  });
}
// 增加快捷键
export function addShortcutKyes(params: any): AxiosPromise {
  return request('/platform/shortcut/addShortcutKeys', {
    method: 'POST',
    data: params
  });
}

// 获取任务状态
export function getTaskMarkedInfo(params: any): AxiosPromise {
  return request('/manager/task/getTaskProcessState', {
    method: 'POST',
    data: params
  });
}

// 获取标注/质检/验收相关人员信息
export function getMarkedUserInfo(params: any): AxiosPromise {
  return request('/platform/check/task/displayPersonInfo', {
    method: 'POST',
    data: params
  });
}

// 提交批注
export function submitAnnotateList(params: any): AxiosPromise {
  return request('/platform/annotation/submit', {
    method: 'POST',
    data: params
  });
}

// 查询批注列表
// export function getAnnotateList(params: any): AxiosPromise {
//   return request('/platform/annotation/getAnnotationDetail', {
//     method: 'POST',
//     data: params
//   });
// }

// 自动质检结果查询
export function getAutoInspect(params: any): AxiosPromise {
  return request('/platform/annotation/getAutoInspectDetail', {
    method: 'POST',
    data: params
  });
}

/**
 * 获取批注列表
 * 1 人工
 * 2 机器
 * 0 全部
 *  */
export function getAnnotateList(params: any): AxiosPromise {
  return request('/platform/annotation/getAnnotationList', {
    method: 'POST',
    data: params
  });
}

export function getConversationContent(
  params: any,
  cancelToken: any = {
    cancelToken: undefined
  }
): any {
  return axios
    .post(`${baseURL}/platform/mark/query_db`, params, {
      timeout: 60000,
      cancelToken: cancelToken.cancelToken,
      headers: {
        Authorization: localStorage.getItem('token')
      }
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}

// 前端主动上报信息-报警接口转发
export function manuallySendAlert(params: any): AxiosPromise {
  return request(`${baseUrl}/manuallySendAlert`, {
    method: 'POST',
    data: params
  });
}

// 获取当前任务详细标注新奇
export function getCurrentTaskInfoDetail(params: any): AxiosPromise {
  return request('/manager/task/getTaskState', {
    method: 'POST',
    data: params
  });
}

export function simulationData(params: any): AxiosPromise {
  return request('/platform/question/simulation', {
    method: 'POST',
    data: params
  });
}
