/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-key */
// @ts-nocheck
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
// eslint-disable-next-line import/extensions
import waterLogo from '@/account/assets/water.png';
import { useStores } from '../../hook/index';
import { allowPageList } from '../../common/allowlist';
import { RouteType } from '../../route/index';
import utils from '../../common/utils';

const waterArr = Array(100).fill(1);
const WaterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  overflow: hidden;
  z-index: 9999;
  pointer-events: none;
`;
const WaterMark = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  background: ${(props) =>
    props.isOrder ? '' : `url(${waterLogo}) no-repeat center`}};
  background-size: 38px;
  pointer-events: none;
  color: #1a1a1a;
  font-size: 13px;
  transform: rotate(-45deg);
  display: inline-block;
  opacity: 0.08;
  &::before {
    content: '${(props) =>
    // eslint-disable-next-line no-nested-ternary
    String(props.phone) === 'undefined' || String(props.phone) === ''
      ? String(props.userId) === 'undefined'
        ? ''
        : props.userId
      : props.phone}';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(20px, -20px);
  }
  &::after {
    content: '${(props) => `${props.siteName} ${utils.getPlatformName()}平台`}';
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(20px, 20px);
  }
`;

const UserInfo: React.FC<{ route?: RouteType }> = observer(({ route }) => {
  const { user, site } = useStores();
  if (window.location.href.indexOf('localhost') !== -1) {
    // 本地开发-运营-1
    // user.token = '6d739ce1734e4762b714834169b0a0d8';
    // user.username = '15611507058';
    // user.nickname = '本地开发-运营-1';
    // user.roleCode = 'OPERATION';
    // user.userId = 20589;
    // user.phone = '15611507058';
    // user.timingCycle = 300000;
    // 本地开发-运营-2
    // user.token = 'c22489c72de942b4a26acee1c08d1fda';
    // user.username = '18200000003';
    // user.nickname = '本地开发-运营-2';
    // user.roleCode = 'OPERATION';
    // user.userId = 22130;
    // user.phone = '18200000003';
    // user.timingCycle = 300000;
    // 本地开发-标注
    // user.username = '17600293383';
    // user.nickname = '本地开发-标注';
    // user.roleCode = 'COMMON';
    // user.userId = 146;
    // user.token = 'f964cc95718b47ddbb9808c4fe497197';
    // user.phone = '17600293383';
    // user.timingCycle = 300000;
    // 本地开发-质检
    // user.username = '17600293383';
    // user.nickname = '本地开发-质检';
    // user.roleCode = 'COMMON';
    // user.userId = 146;
    // user.token = 'fabc6b4938014e62b01418d46d3bcd95';
    // user.phone = '17600293383';
    // user.timingCycle = 300000;
    // 本地开发-标注管理员
    // user.username = '18200000003';
    // user.nickname = '本地开发-标注管理员';
    // user.roleCode = 'GROUP_ADMIN';
    // user.userId = 22130;
    // user.token = '6e00d3a6c1d247ffbf8f5631ccee9063';
    // user.phone = '18200000003';
    // user.timingCycle = 300000;
    // 本地开发-验收
    // user.username = '11020000001';
    // user.nickname = '本地开发-验收';
    // user.roleCode = 'ALGORITHM';
    // user.userId = 14919;
    // user.token = '1e3e05d615344f7099438fb11bb5d224';
    // user.phone = '11020000001';
    // user.timingCycle = 300000;
    // localStorage.setItem('nickname', user.nickname);
    // localStorage.setItem('token', user.token);
    // localStorage.setItem('username', user.username);
    // localStorage.setItem('userId', String(user.userId));
    // localStorage.setItem('phone', user.phone);
    // localStorage.setItem('roleCode', user.roleCode || '');
    // localStorage.setItem('timingCycle', String(user.timingCycle));
  }

  const history = useHistory();
  const { pathname, search } = useLocation<{
    pathname: string;
    search: string;
  }>();

  useEffect(() => {
    user.addUserFromStorage();
    if (!route) {
      return;
    }
    if (route && route.path) {
      if (allowPageList.includes(route.path)) {
        return;
      }
    }
    if (!user || !user.token) {
      history.push(`/login?from=${pathname}${search}`);
      return;
    }
    if (route && route.authority) {
      if (!route.authority.includes(user.roleCode)) {
        history.replace('/result/no-authority');
      }
    }
  }, [history, route, user, pathname]);

  return (
    <>
      <WaterWrapper>
        {waterArr.map((item, index) => (
          <WaterMark
            // eslint-disable-next-line react/no-array-index-key
            key={`water_${index}`}
            isOrder={site.isOrder}
            siteName={site.name}
            phone={user?.phone?.replace(/^(\d{3})(\d{4})(\d{3})/, '$1****$3')}
            userId={user?.userId}
          />
        ))}
      </WaterWrapper>
    </>
  );
});
export default UserInfo;
